import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";
import ImageCarousel from "../components/carousels/media-post-image-carousel/image-carousel";
import useWindowDimensions from "../components/hooks/use-window-dimensions";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const { previous, next } = data;
  const { windowWidth } = useWindowDimensions();

  const months = {
    January: "01",
    Febuary: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  const desktopDate = post.frontmatter.date;
  const dateArray = post.frontmatter.date.split(" ");
  const day = dateArray[1];
  const month = months[dateArray[0]];
  const year = dateArray[2];

  const mobileDate = day + " / " + month + " / " + year;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="padded media-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="title">
          <h1 itemProp="headline" className="blog-title">
            {post.frontmatter.title}
          </h1>
        </header>
        <h1 className="subtitle">{post.frontmatter.subtitle}</h1>
        <p className="date">{windowWidth > 767 ? desktopDate : mobileDate}</p>

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
          className="description"
        />
        <ImageCarousel
          featuredImages={post.frontmatter.featuredImages}
          altText={post.frontmatter.title}
        />
      </article>
      <nav className="padded blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            marginLeft: 0,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD YYYY")
        description
        featuredImages
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
