import * as React from "react";

const ImageCarousel = ({ featuredImages, altText }) => {
  return (
    <div className="image-carousel-wrapper">
      <div className="image-carousel">
        {featuredImages &&
          featuredImages.map((featuredImage, i) => {
            const index = i + 1;
            return (
              <img
                key={i}
                className="image"
                src={featuredImage}
                alt={altText + " image " + index}
              />
            );
          })}
      </div>
      <hr />
    </div>
  );
};

export default ImageCarousel;
